var nav = document.querySelector('#m-nav');
var submenuLinks = nav.querySelectorAll('.m-nav__item--has-submenu > .m-nav__link');
var navOverlay = document.querySelector('.m-nav__overlay');
var menuCheckbox = document.getElementById('showMenu');
var html = document.getElementsByTagName('html')[0];

for (var i = 0, l = submenuLinks.length; i < l; i++) {
    submenuLinks[i].addEventListener('click', function (e) {
        e.preventDefault();

        var activeMenu = nav.querySelector('.js-active');
        if (activeMenu) {
            activeMenu.classList.remove('js-active');
        }

        this.parentNode.classList.add('js-active')
    });
}

navOverlay.addEventListener('click', function () {
    menuCheckbox.checked = false;
    checkboxStatus();
});

menuCheckbox.addEventListener('click', function () {
    checkboxStatus();
});

function checkboxStatus() {
    if (menuCheckbox.checked) {
        html.classList.add('js-menu-open');
    } else {
        html.classList.remove('js-menu-open');
    }
}

var t = window.matchMedia("(min-width: 576px)"); // Switch to desktop menu breakpoint
t.addListener(resetBody), resetBody(t);

function resetBody(t) {
    if (t.matches) {
        html.classList.remove('js-menu-open');
    }
}
