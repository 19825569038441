var VACANCIES = {
    wrapper : $(".vacancies"),
    init : function () {
        if(this.wrapper.length){
            this.attachEvents();
        }
    },
    attachEvents : function () {
        this.wrapper.find(".btn").click(function () {
            var defaultText = VACANCIES.wrapper.attr("data-message-placeholder");
            var positionName = $(this).closest(".vacancy-data").find("h2").text();
            $(".contact-form textarea").val(defaultText + positionName);
        });

        this.wrapper.find(".vacancy").click(function (e) {
            e.preventDefault();

           VACANCIES.wrapper.find(".vacancy").removeClass("is-active");
           $(this).addClass("is-active");

           var target = $(this).attr("href");

           VACANCIES.wrapper.find(".vacancy-data").addClass("is-hidden");

           $(target).removeClass("is-hidden");

           if($(window).width() < 991){
               $([document.documentElement, document.body]).animate({
                   scrollTop: $(".vacancies-list").offset().top - 50
               }, 200);
           }
        });
    }
};

VACANCIES.init();