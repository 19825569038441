var $navigation = $("#m-nav");

var anchors = [];

$(".section").each(function(){
    var anchor = $(this).attr("data-anchor");
    anchors.push(anchor);
});

$('.sections').fullpage({
    //options here
    recordHistory: true,
    autoScrolling: true,
    anchors: anchors,
    navigation: true,
    verticalCentered: true,
    responsiveWidth: 991,
    onLeave: function (index, nextIndex, direction) {
        $(".header, .footer").addClass("is-hidden");
        if($("html").hasClass("js-menu-open")){
            $(".m-nav__button").trigger("click");
        }

        if(!isFPResponsive()){
            setTimeout(function(){
                highLightMenuItem(nextIndex, $navigation);
            }, 200);
        }
    },
    afterLoad : function(){
        $(".header, .footer").removeClass("is-hidden");
    }
});

function isFPResponsive(){
    return $("body").hasClass("fp-responsive");
}

function highLightMenuItem($activeSection, $navigation) {
    $navigation.find("a").removeClass("is-active");
    var anchor = $(".section:nth-child("+$activeSection+")").attr("data-anchor");
    $(".m-nav__list a[href*='"+anchor+"']").addClass("is-active");
}

$("[data-scrollTo]").click(function(e){
    if(isFPResponsive()){
        e.preventDefault();
        var target = $(this).attr("data-scrollto");

        var targetSection = $("[data-anchor="+target+"]");

        if(targetSection.length){
            var targetTop = targetSection.offset().top;

            $([document.documentElement, document.body]).animate({
                scrollTop: targetTop - $(".header").height()
            }, 700, "easeInOutCubic");
        }else{
            window.location.href = $(this).attr("href");
        }
    }
});

$(window).scroll(function () {
    if(isFPResponsive()){
        var windowScrollTop = $(window).scrollTop() + $(".header").height() + 1;

        $(".section").each(function () {
            var section = $(this);
            var sectionTop = section.offset().top;
            var sectionBottom = sectionTop + section.height();

            if(windowScrollTop > sectionTop && windowScrollTop < sectionBottom){
                var index = section.index() + 1;
                highLightMenuItem(index, $navigation);
            }

        });
    }
});
