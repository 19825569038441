var CONTACT = {
    wrapper : $(".contact-form"),
    url : $(".contact-form").attr("data-url") + "/",
    errors : 0,
    init : function(){
        if(this.wrapper.length){
            this.attachEvents();
        }
    },
    attachEvents : function () {
        this.wrapper.find("input, textarea").blur(function(){
            CONTACT.validateField($(this));
        });

        this.wrapper.find(".btn").click(function(e){
            e.preventDefault();
            CONTACT.errors = 0;

            CONTACT.wrapper.find("input:not(.is-hidden), textarea").each(function () {
                var input = $(this);
                CONTACT.validateField(input);
            });

            CONTACT.sendEmail();
        });
    },
    sendEmail : function(){
        if(CONTACT.errors === 0){
            var data = CONTACT.wrapper.serialize();

            CONTACT.wrapper.find(".submit").find("p").remove();

            $.ajax({
                url: CONTACT.url + "contact-ajax.php?type=sendEmail",
                data : data,
                beforeSend: function() {
                    CONTACT.wrapper.find(".btn").parent().addClass("is-loading");
                    CONTACT.wrapper.find(".btn").prop("disabled", true);
                }
            }).done(function( data ) {
                var dataJson = JSON.parse(data);
                if(dataJson.code == 200 || dataJson.code == 300){
                    //200 - sended ok
                    //300 - sended ok - (spam)
                    var message = CONTACT.wrapper.find(".btn").data("message-ok");
                    message = "<p class='green'>" + message + "</p>";
                    CONTACT.wrapper.find(".submit").html(message);

                    CONTACT.wrapper.find("input, textarea").val("");
                }else {
                    var message = CONTACT.wrapper.find(".btn").data("message-fail");
                    //fail
                    message = "<p class='red'>" + message + "</p>";
                    CONTACT.wrapper.find(".submit").append(message);
                }
            }).always(function () {
                CONTACT.wrapper.find(".btn").parent().removeClass("is-loading");
                CONTACT.wrapper.find(".btn").prop("disabled", false);
            }).fail(function () {
                alert("Při pokusu odeslat formulář došlo k chybě. Zkuste to prosím znova. Děkujeme");
            });
        }
    },
    validateField : function ($input) {
        $input.removeClass("has-error");
        var emailRegex = new RegExp(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/gm);
        var textRegex = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g);

        var value = $input.val().trim();
        var type = $input.data("type");

        if(value === ""){
            CONTACT.errors += 1;
            CONTACT.handleErrorMessage($input, "empty")
        }else{
            if(type == "text"){
                if(value == ""){//TODO
                    CONTACT.errors += 1;
                    CONTACT.handleErrorMessage($input, "validation")
                }else{
                    CONTACT.removeErrorMessage($input);
                }
            }else if(type == "email"){
                if(!emailRegex.test(value)){
                    CONTACT.errors += 1;
                    CONTACT.handleErrorMessage($input, "validation")
                }else{
                    CONTACT.removeErrorMessage($input);
                }
            }else if(type == "captcha"){
                $input.parent().addClass("is-loading");

                var captchaHash = $("[name='captcha-hash']").val();
                var captchaValue = $input.val();

                $.ajax({
                    url: CONTACT.url + "contact-ajax.php?type=validateCaptcha&hash=" + captchaHash + "&value=" + captchaValue ,
                    beforeSend: function() {
                        $input.parent().addClass("is-loading");
                        CONTACT.wrapper.find(".btn").prop("disabled", true);
                    }
                }).done(function( data ) {
                    var dataJson = JSON.parse(data);
                    if(dataJson.code == 200){
                        CONTACT.removeErrorMessage($input);
                    }else if(dataJson.code == 300){
                        CONTACT.errors += 1;
                        CONTACT.handleErrorMessage($input, "validation")
                    }
                }).always(function () {
                    $input.parent().removeClass("is-loading");
                    CONTACT.wrapper.find(".btn").prop("disabled", false);
                }).fail(function () {
                    alert("Při pokusu ověřit výsledek došlo k chybě. Zkuste formulář odeslat znova. Děkujeme.");
                });
            }
        }
    },
    handleErrorMessage : function ($input, type) {
        var message = "";
        this.removeErrorMessage($input, false);
        if(type == "empty"){
            message = CONTACT.wrapper.data("required-text");
        }else if(type == "validation"){
            message = $input.data("bad-value-text");
        }

        var htmlMessage = '<ins class="form-error">'+message+'</ins>';

        var $fieldWrap = $input.closest(".field-wrap");
        if($fieldWrap.hasClass("has-label")){
           $($fieldWrap.find(".wrap:nth-child(2) .input-wrap")).html($(htmlMessage));
        }else{
            $(htmlMessage).insertAfter($input);
        }

    },
    removeErrorMessage : function ($input) {
        $input.closest(".field-wrap").find("ins").remove();
    }

};

CONTACT.init();